import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Field, FieldArray } from 'redux-form/immutable'
import { FormattedNumberField, FormField, FormFieldRadioGroup } from '../ui/Form'
import trans from '../../trans'
import { debounce, get } from 'lodash'
import { Col, Row } from '../../ui/Grid'
import { AmountFormatter } from '../AmountFormatter'
import { Alert } from '../../ui/Alert'
import Button from '../../components/ui/ButtonComponent'
import { Tooltip } from '../Tooltip'
import Icon from '../ui/IconComponent'
import localStorage from '../../services/localStorage'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { compose } from 'redux'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { countries } from '../../store/app/countries'
import APIClient from '../../services/APIClient'
import DocumentFormRate from './DocumentFormRate'
import DocumentFormExchangeRateTooltip from './DocumentFormExchangeRateTooltip'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import { MpkSplitField } from '../UserProfilePage/SensitiveData/MpkSplitField'
import { IDocument } from '../../types/document'
import { ITransactionSuggestion } from '../../types/transaction-suggestion'
import { toNumber } from '../../utils/number'

const OCR_HINTS_STORAGE_KEY = 'ocr-hints'

export interface DocumentFormProps {
  document: IDocument
  transactionSuggestions: ITransactionSuggestion[]
  [key: string]: any
}

export interface DocumentFormState {
  [key: string]: any
}

class DocumentForm extends React.Component<DocumentFormProps, DocumentFormState> {
  constructor(props) {
    super(props)

    const hintsVisibility = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    this.state = {
      disabledHints: [],
      initialOcrStatus: get(props.document, 'ocr_status', null),
      areHintsVisible: get(hintsVisibility, `${props.document.id}.visible`, true),
      hintsDirty: get(hintsVisibility, `${props.document.id}.dirty`, false),
      defaultCurrencyGrossFocus: false,
      isAddProviderPopupOpen: false,
    }

    this.debouncedRateChange = debounce(this.onRateChange, 300)
  }

  componentDidMount() {
    const {
      fetchProjects,
      document: { accountDimensionItems = [] },
      setCache,
    } = this.props

    fetchProjects()
    setCache(accountDimensionItems)
  }

  getTravelElementsOptions() {
    const { travelElements } = this.props
    return travelElements.map((element) => ({
      label: trans(element.name),
      value: {
        type: element.type,
        id: element.id,
      },
    }))
  }

  getTransactionOptions() {
    const { transactionSuggestions } = this.props

    return transactionSuggestions.map((transaction) => ({
      label: transaction.transaction.title,
      value: transaction.transaction.id,
    }))
  }

  getCurrenciesOptions() {
    const { currencies } = this.props

    return currencies.map(({ code }) => ({
      label: code,
      value: code,
    }))
  }

  acceptHint(hint) {
    return new Promise((resolve) => {
      this.props.acceptHint(hint, this.props).then((hint) => {
        const { disabledHints } = this.state
        disabledHints.push(hint['id'])
        this.setState({ disabledHints, initialOcrStatus: 'processed' })
        this.setHintsDirty()
        this.props.fetch()
        resolve()
      })
    })
  }

  acceptProvider(hint) {
    hint.name = hint.label

    APIClient.createProvider(hint).then((createdProvider) => {
      this.acceptHint(hint).then(() => {
        this.updateProviders(createdProvider.data)
      })
    })
  }

  rejectHint(hint) {
    return new Promise((resolve) => {
      this.props.rejectHint(hint, this.props).then((hint) => {
        const { disabledHints } = this.state
        disabledHints.push(hint['id'])
        this.setState({ disabledHints, initialOcrStatus: 'processed' })
        this.setHintsDirty()
        resolve()
      })
    })
  }

  acceptHintOrProvider(hint) {
    switch (hint.column) {
      case 'provider_suggested':
        this.acceptProvider(hint)
        break

      default:
        this.acceptHint(hint)
    }
  }

  getAllOcrHints() {
    const {
      document: { ocrHints, ocrSuggestedProvider, instance_id },
      transactionSuggestions,
    } = this.props
    const { disabledHints } = this.state
    let allHints = [...ocrHints]

    if (ocrSuggestedProvider.length) {
      allHints = [
        ...allHints,
        {
          ...ocrSuggestedProvider[0],
          instance_id,
        },
      ]
    }

    return allHints.filter(
      ({ id, accepted }) => disabledHints.indexOf(id) === -1 && accepted === null,
    )
  }

  acceptAllHints() {
    const ocrHints = this.getAllOcrHints()

    ocrHints
      .filter((hint) => hint.column !== 'transaction')
      .forEach((hint) => {
        this.acceptHintOrProvider(hint)
      })
  }

  closeHints = () => {
    const { document } = this.props
    let data = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    data = {
      ...data,
      [document.id]: {
        visible: false,
      },
    }

    localStorage.set(OCR_HINTS_STORAGE_KEY, data)
    this.setState({ areHintsVisible: false })
  }

  setHintsDirty() {
    const { document } = this.props
    let data = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    data = {
      ...data,
      [document.id]: {
        dirty: true,
      },
    }

    localStorage.set(OCR_HINTS_STORAGE_KEY, data)
    this.setState({ hintsDirty: true })
  }

  renderHints() {
    const {
      document: { abilities, ocr_status },
    } = this.props
    const ocrHints = this.getAllOcrHints()

    const hintsList = []

    if (!abilities.edit || !this.state.areHintsVisible || ocr_status === 'unprocessable') {
      return null
    }

    if (this.state.hintsDirty && !ocrHints.length) {
      return null
    }

    ocrHints.map((hint) => {
      hintsList.push(
        <Alert
          className='alert-warning'
          key={hint['id']}
          handleAcceptClick={() => this.acceptHintOrProvider(hint)}
          handleRejectClick={() => this.rejectHint(hint)}
        >
          {trans(`document.ocr-hint-${hint['column']}`)} <span>{hint['label']}</span>
        </Alert>,
      )
    })

    return (
      <div>
        <div className='is-font-semibold-weight' style={{ marginTop: '30px' }}>
          {trans('document.ocr-hint-automatic-recognition')}
        </div>

        {['pending', 'processing', 'wfm'].indexOf(ocr_status) !== -1 && (
          <div style={{ height: 70, position: 'relative' }}>
            <LoadingOverlay xs />
          </div>
        )}

        {hintsList.length > 0 && hintsList}

        {((ocr_status === 'processed' && hintsList.length === 0) || ocr_status === 'failed') && (
          <Row style={{ marginBottom: '30px' }}>
            <Col xs={12}>
              <small className='no-ocr-hints'>{trans('document.no-ocr-hints')}</small>
            </Col>
          </Row>
        )}

        <Row>
          <Col is_pull_end xs={12}>
            <Button
              className='is-margin-right-small'
              outline
              xs
              onClick={(e) => {
                e.preventDefault()
                this.closeHints()
              }}
            >
              {trans('document.close-hints')}
            </Button>

            {hintsList.length > 0 && (
              <Button
                primary
                xs
                onClick={(e) => {
                  e.preventDefault()
                  this.acceptAllHints()
                }}
              >
                {trans('document.accept-all-hints')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  calculateRateFromGrossChange = (gross, defaultCurrencyGross) => {
    const result = parseFloat(defaultCurrencyGross) / parseFloat(gross)
    return result ? result.toFixed(8).toString() : null
  }

  onDefaultCurrencyGrossChange = (value) => {
    const { data } = this.props
    const result = this.calculateRateFromGrossChange(data.gross, value)

    if (this.state.defaultCurrencyGrossFocus) {
      this.debouncedRateChange(null, result)
    }
  }

  openPopup = () => {
    this.setState({ isAddProviderPopupOpen: true })
  }

  closePopup = () => {
    this.setState({ isAddProviderPopupOpen: false })
  }

  onRateChange = (event, rate) => {
    const { change } = this.props
    change('exchange_rate', rate)
  }

  onRateSuggestionAccept = (event, rate) => {
    const { change } = this.props
    change('default_exchange_rate_accepted', true)
  }

  onTransactionChange = (event, transactionId) => {
    const { transactionSuggestions } = this.props
    const suggestion = transactionSuggestions.find(
      (suggestion) => suggestion.transaction.id === transactionId,
    )

    if (!suggestion) {
      return
    }

    const billingValue = toNumber(suggestion.transaction.billing_value)

    this.setState(
      {
        defaultCurrencyGrossFocus: true,
      },
      () => {
        this.onDefaultCurrencyGrossChange(billingValue)
        this.setState({ defaultCurrencyGrossFocus: false })
      },
    )
  }

  updateProviders = (provider) => {
    const { dispatch, change } = this.props

    dispatch(change('provider_id', parseInt(provider.id)))
  }

  renderProvider() {
    if (!this.props.document.provider) {
      return <p style={{ color: '#848484' }}>{trans('document.no-provider')}</p>
    }

    return ''
  }

  render() {
    const {
      getValue,
      defaultCurrency,
      data,
      accountDimensions,
      hasMpkFeatureEnabled,
      hasProjectFeatureEnabled,
      projects,
      dispatch,
      change,
      document,
      countries,
      document: {
        exchange_rate,
        default_exchange_rate,
        abilities,
        can_edit_exchange_rate,
        last_exchange_rate,
        instance_id,
        default_exchange_rate_date,
      },
      transactionSuggestionsLoading,
      amountLoader,
    } = this.props

    const { edit, edit_basic: editBasic, add_custom_provider: addProvider } = abilities
    const gross = getValue('gross') ? getValue('gross') : '0,00'
    const currency = getValue('currency')
    const type = getValue('type')
    const isGeneratedByBilling = document.self_generated

    const {
      userProfile: {
        selectors: { data: userData },
      },
    } = this.props

    const paymentTypes = document.request.type === 'invoice'
      ? [
        {
          value: 'transfer',
          label: trans('document.transfer'),
        },
      ]
      : [
        {
          value: 'service_card',
          label: trans('document.service-card'),
        },
        {
          value: 'own',
          label: trans('document.own'),
          disabled: userData.abilities.isAgent,
        },
        {
          value: 'corporate_card',
          label: trans('document.corporate-card'),
          disabled:
            (userData.abilities.isRegular || !isGeneratedByBilling) &&
            !userData.abilities.isAgent,
        },
      ];

    const isCurrencyLoaded = last_exchange_rate && last_exchange_rate.currency.code === currency

    const itemsForDocumentType = [
      {
        value: 'accounting',
        label: trans('document.accounting-document'),
      },
      document['type'] === 'confirmation'
        ? {
            value: 'confirmation',
            label: trans('document.other-document'),
          }
        : {
            value: 'travel',
            label: trans('document.other-document'),
          },
    ]

    let convertedGross =
      this.props.document && this.props.document.converted_gross
        ? this.props.document.converted_gross.toFixed(2)
        : undefined

    return (
      <form className='document-form'>
        <div className={editBasic ? 'force-edit' : ''}>
          <Field
            component={FormField}
            label={trans('document.request-item')}
            type='select'
            name='request_element'
            options={this.getTravelElementsOptions()}
            labeltop
          />
        </div>

        <Field
          name='type'
          component={FormFieldRadioGroup}
          label={
            <>
              {trans('document.document-type')}
              <Tooltip
                style={{ marginLeft: 5 }}
                html={
                  <>
                    <p>
                      <strong>{trans('document.accounting-travel-list')}</strong>:{' '}
                      {trans('document.type-accounting-descritpion')}
                    </p>
                    <br />
                    <p>
                      <strong>{trans('document.other-document')}</strong>:{' '}
                      {trans('document.type-other-descritpion')}
                    </p>
                  </>
                }
              >
                <Icon className='request-list__column-name-icon' type='question_fill' />
              </Tooltip>
            </>
          }
          items={itemsForDocumentType}
          inline
          labeltop
        />
        {(type === 'accounting' || type === 'confirmation') && (
          <div>
            {this.renderHints()}

            <Field
              name='document_number'
              type='text'
              component={FormField}
              label={trans('document.document-number')}
              labeltop
            />
            <Field
              name='issue_date'
              type='datepicker'
              component={FormField}
              label={trans('document.document-date')}
              placeholderText={trans('global.datepicker-placeholder')}
              todayButton={trans('Today')}
              dateFormat='dd.MM.yyyy'
              labeltop
            />
            <div className={editBasic ? 'force-edit' : ''}>
              <Field
                name='annotation'
                type='text'
                component={FormField}
                label={trans('document.annotation')}
                labeltop
              />
            </div>

            <Row
              style={{
                display: document['type'] === 'confirmation' ? 'none' : 'flex',
              }}
            >
              <Col sm={12}>
                <Field
                  name='payment'
                  component={FormFieldRadioGroup}
                  label={
                    <>
                      {trans('document.payment')}
                      <Tooltip
                        html={
                          <div>
                            <p>
                              <strong>{trans('document.payment-tooltip-personal-card')}</strong>:{' '}
                              {trans('document.payment-tooltip-personal-card-description')}
                            </p>
                            <br />
                            <p>
                              <strong>{trans('document.payment-tooltip-own-funds')}</strong>:{' '}
                              {trans('document.payment-tooltip-own-funds-description')}
                            </p>
                          </div>
                        }
                      >
                        <Icon grey lg type='icon-info-grey' style={{ marginLeft: 7 }} />
                      </Tooltip>
                    </>
                  }
                  items={paymentTypes}
                  inline
                  labeltop
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={7} style={{ paddingRight: '0' }}>
                    {edit ? (
                      <Field
                        name='gross'
                        type='amount'
                        component={FormField}
                        label={trans('document.gross')}
                        placeholder='0,00'
                        onChange={(e) => e.preventDefault()}
                        options={{ numeral: true, numeralThousandsGroupStyle: 'none' }}
                        labeltop
                      />
                    ) : (
                      <div className='form-group form-group--label-top'>
                        <span className='form-group__label'>{trans('document.gross')}</span>

                        <div
                          className='input input--text'
                          style={{ marginTop: '2px', paddingLeft: 0 }}
                        >
                          <AmountFormatter amount={gross} /> {currency}
                        </div>
                      </div>
                    )}
                  </Col>
                  {edit && (
                    <Col sm={5} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                      <Field
                        name='currency'
                        type='currency'
                        component={FormField}
                        currencies={this.getCurrenciesOptions()}
                        label={trans('document.currency')}
                        isMulti
                        labeltop
                        disabled={!this.props.document.abilities.edit || !data.payment}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm={6}>
                {data.currency !== defaultCurrency && amountLoader.isLoading === false ? (
                  <div className='form-group form-group--label-top'>
                    <span className='form-group__label'>
                      {trans('document.exchanged-amount') + ' ' + defaultCurrency}
                    </span>
                    <div className='account-document-page__rate'>
                      <FormattedNumberField
                        value={convertedGross}
                        onChange={this.onDefaultCurrencyGrossChange}
                        disabled={!data.gross || !can_edit_exchange_rate || !isCurrencyLoaded}
                        onBlur={() => {
                          this.setState({
                            ...this.state,
                            defaultCurrencyGrossFocus: false,
                          })
                        }}
                        onFocus={() => {
                          this.setState({
                            ...this.state,
                            defaultCurrencyGrossFocus: true,
                          })
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <Field
                    name='dummy4'
                    component={FormField}
                    type='html'
                    html={() => {
                      return (
                        <div style={{ marginTop: '7px' }}>
                          <strong className='document__amount has-loader'>
                            {amountLoader.isLoading ? (
                              <Loader small />
                            ) : (
                              <AmountFormatter amount={convertedGross} />
                            )}
                          </strong>
                        </div>
                      )
                    }}
                    label={trans('document.exchanged-amount') + ' ' + defaultCurrency}
                    labeltop
                    canEdit={can_edit_exchange_rate}
                  />
                )}
              </Col>
            </Row>
            {defaultCurrency !== currency && (
              <Row>
                <Col sm={6}>
                  {isCurrencyLoaded ? (
                    <div style={{ position: 'relative' }}>
                      <div className='form-group form-group--label-top'>
                        <DocumentFormRate
                          document={document}
                          onRateChange={this.onRateChange}
                          isAccounting={false}
                          onRateSuggestionAccept={this.onRateSuggestionAccept}
                        />
                      </div>

                      <DocumentFormExchangeRateTooltip document={document.request.type} />
                    </div>
                  ) : (
                    <div
                      className={classNames({
                        'exchange-rate--loader': true,
                        thin: document.default_exchange_rate,
                      })}
                    >
                      <Loader small />
                    </div>
                  )}
                </Col>
              </Row>
            )}

            {document._links.transaction_suggestions && (
              <Row>
                <Col sm={12}>
                  <Field
                    component={FormField}
                    label={trans('document.transaction')}
                    type='select'
                    name='transaction'
                    options={this.getTransactionOptions()}
                    onChange={this.onTransactionChange}
                    clearable
                    isLoading={transactionSuggestionsLoading}
                    labeltop
                  />
                </Col>
              </Row>
            )}
          </div>
        )}
        {type === 'travel' && (
          <div>
            <Field
              name='annotation'
              type='text'
              component={FormField}
              label={trans('document.annotation')}
              labeltop
            />
          </div>
        )}
        {hasMpkFeatureEnabled && (
          <Row>
            <Col sm={12}>
              <FieldArray
                name='mpks'
                label={trans('document-element.mpk')}
                component={MpkSplitField}
                loadOptions={fetchMpkForAsyncSelect()}
                labeltop
                selectedOption={data.mpks}
                placeholder={trans('instance-users.none')}
                noOptionsMessage={() => {
                  return trans('user.no-results-for-search-message')
                }}
                loadingMessage={() => {
                  return trans('user.searching-message')
                }}
                disabled={!edit}
                clearable={true}
              />
            </Col>
          </Row>
        )}
        {hasProjectFeatureEnabled && projects.length > 0 && (
          <Row>
            <Col sm={12}>
              <Field
                name='project_id'
                type='select'
                label={trans('document-element.project')}
                component={FormField}
                options={projects}
                clearable={true}
                emptyValue={{
                  label: trans('trip-request.empty-project-option-label'),
                  value: null,
                }}
                disabled={!edit}
                labeltop
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            {accountDimensions.map((dimension) => (
              <Field
                name={dimension.fieldName}
                type='account-dimensions'
                component={FormField}
                accountDimension={dimension}
                selectedDimensions={document.accountDimensionItems}
                placeholder={trans('account-dimensions.placeholder')}
                label={dimension.label}
                labeltop
                asField
                disabled={!edit}
                key={dimension.id}
              />
            ))}
          </Col>
        </Row>
      </form>
    )
  }
}

DocumentForm.propTypes = {
  document: PropTypes.object.isRequired,
  travelElements: PropTypes.array.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  hasMpkFeatureEnabled: PropTypes.bool.isRequired,
  hasProjectFeatureEnabled: PropTypes.bool.isRequired,
}

const withProfile = userProfile(false, false)
const withCountries = countries(false, false)

DocumentForm = compose(withProfile, withCountries)(DocumentForm)

export { DocumentForm }
export default { DocumentForm }
