import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import APIClient from '../../services/APIClient'
import { compose } from 'redux'
import { documents } from '../../store/app/document-list'
import { isEqual } from 'lodash'
import { RequestType } from '../../types/request'

class RequestTravelDocumentsManagerBase extends React.Component<any, any> {
  uploadDocument = (request, files) => {
    const { element, upload } = this.props
    const documentType = request.type === 'expense' ? 'accounting' : null

    for (let file of files) {
      upload(file, () => {
        return APIClient.uploadElementDocument(
          request['slug'],
          element.type,
          element.id,
          file,
          null,
          documentType,
        )
      })
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(nextProps.documentsList, this.props.documentsList)
  }

  render() {
    const { children, instanceCurrency, request, currentUser, documentsList } = this.props
    const canUploadDocuments = request.type !== RequestType.INVOICE || documentsList.length === 0;

    const renderProps = {
      instanceCurrency,
      request,
      currentUser,
      documentsList,
      canUploadDocuments,
      uploadDocument: this.uploadDocument,
      deleteDocument: this.props.delete,
    }

    return children(renderProps)
  }
}

RequestTravelDocumentsManagerBase.propTypes = {
  request: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
})

const withConnect = connect(mapStateToProps)
const withDocuments = documents()

const RequestElementDocumentsManager = compose(
  withDocuments,
  withConnect,
)(RequestTravelDocumentsManagerBase)

export { RequestElementDocumentsManager }
export default { RequestElementDocumentsManager }
