export const TYPE_TAXI = 'cost-type.taxi' //Taxi
export const TYPE_PARKING = 'cost-type.parking' //Parking
export const TYPE_OTHER_BUSINESS_TRIPS = 'cost-type.other-business-trips' //Inne przejazdy służbowe
export const TYPE_INTERNAL_MEETING = 'cost-type.internal-meeting' //Spotkanie wewnętrzne
export const TYPE_MEETING_WITH_CONTRACTOR = 'cost-type.meeting-with-contractor' //Spotkanie z kontrahentem
export const TYPE_REPRESENTATION = 'cost-type.representation' //Reprezentacja
export const TYPE_SERVICE_MATERIALS = 'cost-type.service-materials' //Materiały serwisowe
export const TYPE_SHOPPING_FOR_THE_OFFICE = 'cost-type.shopping-for-the-office' //Zakupy do biura
export const TYPE_SMALL_EQUIPMENT = 'cost-type.small-equipment' //Drobne wyposażenie
export const TYPE_OTHER_MATERIALS = 'cost-type.other-materials' //Pozostałe materiały
export const TYPE_OTHER_SERVICES = 'cost-type.other-services' //Pozostałe usługi
export const TYPE_FUEL = 'cost-type.fuel' //Paliwo
export const TYPE_CAR_MAINTENANCE = 'cost-type.car-maintenance' //Utrzymanie samochodów
export const TYPE_SUBSCRIPTIONS = 'cost-type.subscriptions' //Licencje/prenumeraty
export const TYPE_OTHERS = 'cost-type.others' // Inne
export const TYPE_PERIODIC = 'cost-type.periodic' // Inne

export const TYPES = [
  TYPE_TAXI,
  TYPE_PARKING,
  TYPE_OTHER_BUSINESS_TRIPS,
  TYPE_INTERNAL_MEETING,
  TYPE_MEETING_WITH_CONTRACTOR,
  TYPE_REPRESENTATION,
  TYPE_SERVICE_MATERIALS,
  TYPE_SHOPPING_FOR_THE_OFFICE,
  TYPE_SMALL_EQUIPMENT,
  TYPE_OTHER_MATERIALS,
  TYPE_OTHER_SERVICES,
  TYPE_FUEL,
  TYPE_CAR_MAINTENANCE,
  TYPE_SUBSCRIPTIONS,
  TYPE_OTHERS,
  TYPE_PERIODIC,
]
