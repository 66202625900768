import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import useAccountPayments from './hooks/useAccountPayments'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import AccountPaymentsPageFilters from '../AccountPaymentsPageFilters'
import AccountsPaymentsPageTable from '../AccountPaymentsPageTable'

function AccountPaymentsPage() {
  const account = '** **** **** **** **** XXXXX'
  const { data, paginator, loading, setPage } = useAccountPayments()
  const params = useParams()

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card-accounts')}>
          {trans('main-menu.my-card-accounts')}
        </Link>
        <Link to={getRouteByName('main', 'my-card-accounts-statements', params)}>
          {trans('statements.statements_page_title')}
        </Link>
        <Link to={getRouteByName('main', 'my-card-accounts-entries', params)}>
          {trans('payments.payments_page_title')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards account-page'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('payments.payments_page_title')}
        >
          {data && data.length > 0 && (
            <div>
              IBAN: {data[0].iban}
              <br />
              {trans('my-card.account_details_for_period')}: {data[0].statement_range_date}
              <br />
              {trans('my-card.account_details_id')}: {data[0].statement_number}
            </div>
          )}
        </SectionHeader>

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <AccountPaymentsPageFilters />
              {loading && <LoadingOverlay />}
              {!loading && (
                <AccountsPaymentsPageTable data={data} paginator={paginator} setPage={setPage} />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

export default AccountPaymentsPage
