import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import trans from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { REQUEST_DECISIONS } from '../../../store/app/request-action/index'
import { Row, Col } from '../../../ui/Grid/index'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestActionManager } from '../../../containers/RequestActionManager/index'
import { RequestAction } from '../../RequestAction/index'
import { Radio } from '../../../ui/index'
import { RouteManager } from '../../../containers/RouteManager/index'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { withRouter } from 'react-router-dom'
import { OtherCostsManager } from '../../../containers/OtherCostsManager'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { Preview as OtherCostPreview } from '../../OtherCostForm'
import { OtherCostForm } from '../../../containers/OtherCostForm'
import { TimelineAdd } from '../../Timeline'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import { RequestType } from '../../../types/request'

class ExpenseRequestPageWaitingForAcceptanceComponent extends React.Component<any, any> {
  isSummaryPage(location) {
    return location.pathname.indexOf('/summary/') !== -1
  }

  render() {
    const { request, cancelRequest, currencies } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <section className='section'>
              <header className='section__header'>
                <h2 className='h2 section__header-title'>{trans('request.items')}</h2>
              </header>
              <OtherCostsManager request={request}>
                {({ elements, types, onAdd, onEdit, onRemove, isElementOpen, isSaving }) => {
                  return (
                    <div className='timeline-container timeline-container--other-costs has-loader'>
                      {isSaving && <Loader />}

                      {!request['abilities']['edit'] &&
                        elements.map((element) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostPreview
                              key={key}
                              form={key}
                              element={element}
                              request={request}
                              types={types}
                            />
                          )
                        })}
                      {request['abilities']['edit'] &&
                        elements.map((element) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostForm
                              key={key}
                              form={key}
                              element={element}
                              onRemove={onRemove}
                              onEdit={onEdit}
                              request={request}
                              currencies={currencies}
                              label=' '
                              types={types}
                              isElementOpen={isElementOpen(element)}
                            />
                          )
                        })}

                      <Ability ability={['edit']}>
                        <TimelineAdd icon='plus' gradient='true' onClick={() => onAdd()} last>
                          {trans('global.add')}
                        </TimelineAdd>
                      </Ability>
                    </div>
                  )
                }}
              </OtherCostsManager>
            </section>

            {this.isSummaryPage(location) && (
              <div className='summary-settlement'>
                <ExpenseRequestSummary request={request} />
              </div>
            )}
            {!this.isSummaryPage(location) && <ExpenseRequestSummary request={request} />}

            <Ability ability={['reject', 'returnToImprovement', 'accept']} comparator='or'>
              <RequestActionManager request={request}>
                {({ comment, decision, onChange, onAction }) => (
                  <RequestAction
                    comment={comment}
                    decision={decision}
                    onChange={onChange}
                    onAction={onAction}
                  >
                    <Row>
                      <Col xs={12} is_pull_end>
                        <Ability ability={['reject']}>
                          <Radio
                            name='decision'
                            label={trans('request.reject')}
                            onClick={() => onChange('decision', REQUEST_DECISIONS.REJECT)}
                            checked={decision === REQUEST_DECISIONS.REJECT}
                          />
                        </Ability>

                        <Ability ability={['returnToImprovement']}>
                          <Radio
                            name='decision'
                            label={trans('request.return-to-improvement')}
                            onClick={() =>
                              onChange('decision', REQUEST_DECISIONS.RETURN_TO_IMPROVEMENT)
                            }
                            checked={decision === REQUEST_DECISIONS.RETURN_TO_IMPROVEMENT}
                          />
                        </Ability>

                        <Ability ability={['accept']}>
                          <Radio
                            name='decision'
                            label={trans('request.accept')}
                            onClick={() => onChange('decision', REQUEST_DECISIONS.ACCEPT)}
                            checked={decision === REQUEST_DECISIONS.ACCEPT}
                          />
                        </Ability>
                      </Col>
                    </Row>
                  </RequestAction>
                )}
              </RequestActionManager>
            </Ability>

            <div className='button-group button-group--fake'>
              <Ability ability={['cancel']}>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    cancelRequest().then(() => {
                      this.props.history.goBack()
                    })
                  }}
                  link
                >
                  {request.type === RequestType.INVOICE ?
                    trans('ui.cancel-order') :
                    trans('ui.cancel-request')
                  }
                </Button>
              </Ability>
            </div>

            <RequestComments comments={request['comments']} />
          </div>
        )}
      </RouteManager>
    )
  }
}

ExpenseRequestPageWaitingForAcceptanceComponent.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export const ExpenseRequestPageWaitingForAcceptance = withRouter(
  ExpenseRequestPageWaitingForAcceptanceComponent,
)
export default { TripRequestPageWaitingForAcceptance: ExpenseRequestPageWaitingForAcceptance }
