import PropTypes from 'prop-types'
import React from 'react'
import * as constants from '../../constants/request'
import {
  STATUS_DRAFT,
  STATUS_TRIP,
  STATUS_UPCOMING_TRIP,
  STATUS_WAITING_FOR_ACCEPTANCE,
} from '../../constants/request'
import { RouteManager } from '../../containers/RouteManager'
import { TripRequestSummaryManager } from '../../containers/RequestSummaryManager'
import { RequestSummary } from '../RequestSummary'
import { RequestBalance } from '../RequestBalance'
import { RequestType } from '../../types/request'

class TripRequestSummary extends React.Component<any, any> {
  isSummaryPage(location) {
    return location.pathname.indexOf('/summary') !== -1
  }

  canShowBalance(status, type, location) {
    const excludedStatuses = [
      constants.STATUS_UPCOMING_TRIP,
      constants.STATUS_WAITING_FOR_ACCEPTANCE,
      constants.STATUS_DRAFT,
      constants.STATUS_TRIP,
    ]

    if (type === RequestType.INVOICE) {
      return false;
    }

    return !excludedStatuses.includes(status) || this.isSummaryPage(location)
  }

  render() {
    const { request, currencies, sendToAcceptance } = this.props
    const {
      request: { basicSummary: summary },
    } = this.props
    const displaySummaryWarnings = (request) => {
      const statuses = [
        STATUS_DRAFT,
        STATUS_WAITING_FOR_ACCEPTANCE,
        STATUS_UPCOMING_TRIP,
        STATUS_TRIP,
      ]
      return statuses.indexOf(request['status']) === -1
    }

    return (
      <RouteManager>
        {({ push, getRouteByName, location }) => (
          <TripRequestSummaryManager request={request}>
            {({ summary, request, balance }) => (
              <div data-test='trip-summary'>
                {this.isSummaryPage(location) && (
                  <div>
                    <RequestSummary
                      push={push}
                      getRouteByName={getRouteByName}
                      request={request}
                      summary={summary}
                      hideNoResultsLabel
                    />
                  </div>
                )}

                {!this.isSummaryPage(location) && (
                  <div>
                    <RequestSummary
                      push={push}
                      getRouteByName={getRouteByName}
                      request={request}
                      summary={summary}
                      grid={{ contentCell: 5, requestedCell: 3, iconsCell: 2, settledCell: 2 }}
                      hideNoResultsLabel
                    />
                  </div>
                )}

                {this.canShowBalance(request.status, request.type, location) && (
                  <RequestBalance balance={balance} noBorder={false} />
                )}
              </div>
            )}
          </TripRequestSummaryManager>
        )}
      </RouteManager>
    )
  }
}

TripRequestSummary.propTypes = {
  request: PropTypes.object.isRequired,
}

export { TripRequestSummary }
export default { TripRequestSummary }
