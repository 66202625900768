import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { Col, Row } from '../../ui/Grid'
import { UserProfile } from '../UserProfile'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import TruncateBreakable from '../TruncateBreakable/TruncateBreakable'
import { RequestState, RequestType } from '../../types/request'

class RequestHeader extends React.Component<any, any> {
  getHeaderLabel(): string {
    const {
      request: { state, type },
    } = this.props

    if (type === RequestType.INVOICE) {
      if (state === RequestState.STATE_SETTLEMENT) {
        return 'request.invoice_settlement'
      }

      return 'request.invoice'
    }

    return `request.${state}`
  }

  render() {
    const {
      request: { type, name, user, uid, company },
      children,
    } = this.props

    const isInvoice = type === RequestType.INVOICE
    const header = this.getHeaderLabel()
    const idLabel = isInvoice ? 'request.id-invoice-label' : 'request.id-label'

    return (
      <Section data-test='request-header'>
        <Row>
          <Col xs={8}>
            <SectionHeader caption={trans(header)} noMargin>
              {name && (
                <span className='section__header-subtitle'>
                  <TruncateBreakable text={name} lines={2} />
                </span>
              )}
              <span className='section__header-subtitle'>
                {!!uid && trans(idLabel)} {!!uid && ':'} {uid}
              </span>

              {children}
            </SectionHeader>
          </Col>
          <Col xs={4}>
            <div className='user-header__wrapper'>
              <div>
                <SectionHeader
                  caption={trans('global.employee-info')}
                  noMargin
                  className='user-header__title'
                />
                <UserProfile user={user} company={company} />
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    )
  }
}

RequestHeader.propTypes = {
  request: PropTypes.object.isRequired,
}

RequestHeader = userProfile(false)(RequestHeader)

export { RequestHeader }
export default { TripRequestHeader: RequestHeader }
