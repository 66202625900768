import PropTypes from 'prop-types'
import React from 'react'
import _, { isEmpty } from 'lodash'
import * as constants from '../../constants/request'
import { RouteManager } from '../../containers/RouteManager'
import { TripRequestSummaryManager } from '../../containers/RequestSummaryManager'
import { RequestSummary } from '../RequestSummary'
import { RequestBalance } from '../RequestBalance'
import { connect } from 'react-redux'
import { getRequestData } from '../../store/app/trip-request'
import { STATE_SETTLEMENT, STATUS_TRIP } from '../../constants/request'
import { RequestType } from '../../types/request'

class ExpenseRequestSummary extends React.Component<any, any> {
  shouldBalanceRender(balance) {
    return !_.isEmpty(balance)
  }

  renderBalance(request, balance) {
    if (
      request.type !== RequestType.INVOICE &&
      request.state === STATE_SETTLEMENT &&
      request.status !== STATUS_TRIP &&
      this.shouldBalanceRender(balance)
    ) {
      return <RequestBalance balance={balance} noBorder={true} />
    }

    return null
  }

  render() {
    const request = this.props.expenseRequest

    return (
      <section className='section section--no-border section--expense'>
        <TripRequestSummaryManager request={request}>
          {({ summary, request, balance }) => (
            <div>
              <RequestSummary
                request={request}
                summary={summary}
                balance={balance}
                hideNoResultsLabel
              />

              {this.renderBalance(request, balance)}
            </div>
          )}
        </TripRequestSummaryManager>
      </section>
    )
  }
}

ExpenseRequestSummary.propTypes = {
  request: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  expenseRequest: getRequestData(state).data,
})

ExpenseRequestSummary = connect(mapStateToProps)(ExpenseRequestSummary)

export { ExpenseRequestSummary }
export default { ExpenseRequestSummary }
