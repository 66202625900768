import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import { Col, Row } from '../../../ui/Grid/index'
import { REQUEST_DECISIONS } from '../../../store/app/request-action/index'
import { TripRequestSummary } from '../TripRequestSummary'
import { RequestActionManager } from '../../../containers/RequestActionManager/index'
import { Radio, FormGroup, Textarea } from '../../../ui/index'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { AccountingDocuments } from '../../AccountingDocuments'
import { TimelineElementsCompliance } from '../TimelineElementsCompliance'
import Button from '../../ui/ButtonComponent'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import PrintRequestDocuments from '../../PrintRequestDocuments'

class TripRequestPageAcceptanceSettlement extends React.Component<any, any> {
  render() {
    const { request, fetchRequest, unrealizeTrip, isModuleActive } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          showMileageAllowance={request.abilities.can_account_delegation}
        />

        <AccountingDocuments
          request={request}
          fetchRequest={fetchRequest}
          disableUpload={true}
          disableDelete={true}
          showMileageAllowance={!request.abilities.can_account_delegation}
          readOnly={true}
          types={['travel', 'confirmation']}
        />

        <TripRequestSummary request={request} />

        <TimelineElementsCompliance />

        <Ability ability={['acceptSettlement', 'returnToSettlementImprovement']} comparator='nor'>
          <PrintRequestDocuments request={request} />
        </Ability>

        <Ability ability={['acceptSettlement', 'returnToSettlementImprovement']} comparator='or'>
          <RequestActionManager request={request}>
            {({ comment, decision, onChange, onAction }) => (
              <>
                <Row>
                  <Col sm={1} />
                  <Col sm={11}>
                    <span className='comments__label'>{trans('comment.comment')}</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={1} />
                  <Col sm={11}>
                    <FormGroup labeltop>
                      <Textarea
                        onChange={(e) => onChange('comment', e.target.value)}
                        value={comment}
                        placeholder={trans('request.add-comment')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'flex-end', marginRight: '-10px' }}>
                  <Row>
                    <Col xs={12} is_pull_end>
                      <Ability ability={['returnToSettlementImprovement']}>
                        <Radio
                          name='decision'
                          label={trans('request.return-to-improvement')}
                          onClick={() =>
                            onChange('decision', REQUEST_DECISIONS.SETTLEMENT_RETURN_TO_IMPROVEMENT)
                          }
                          checked={decision === REQUEST_DECISIONS.SETTLEMENT_RETURN_TO_IMPROVEMENT}
                        />
                      </Ability>

                      <Radio
                        name='decision'
                        label={trans('global.print-accounting-documents')}
                        onClick={() => {
                          onChange('decision', REQUEST_DECISIONS.PRINT)
                        }}
                        checked={decision === REQUEST_DECISIONS.PRINT}
                      />

                      <Ability ability={['acceptSettlement']}>
                        <Radio
                          name='decision'
                          label={trans('request.confirm')}
                          onClick={() => onChange('decision', REQUEST_DECISIONS.SETTLEMENT_ACCEPT)}
                          checked={decision === REQUEST_DECISIONS.SETTLEMENT_ACCEPT}
                        />
                      </Ability>
                    </Col>
                  </Row>
                </Row>
                <div className='request-button-group is-margin-top'>
                  <Button pull_end primary onClick={onAction} disabled={!decision}>
                    {trans('global.perform')}
                  </Button>
                </div>
              </>
            )}
          </RequestActionManager>
        </Ability>

        <RequestCommentManager request={request}>
          {({ comments }) => {
            return <RequestComments comments={comments} />
          }}
        </RequestCommentManager>
      </div>
    )
  }
}

TripRequestPageAcceptanceSettlement.propTypes = {
  request: PropTypes.object.isRequired,
  unrealizeTrip: PropTypes.func.isRequired,
}

export { TripRequestPageAcceptanceSettlement }
export default { TripRequestPageAcceptanceSettlement }
