import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import Button from '../ui/ButtonComponent'
import { RouteManager } from '../../containers/RouteManager'
import { InvoiceRequestsManager } from '../../containers/InvoiceRequestsManager'
import { InvoiceRequestsListTable } from '../InvoiceRequestsListTable'
import { InvoiceRequestsListFilters } from '../InvoiceRequestsListFilters'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { compose } from 'redux'
import { Ability } from '../RequestPageCommon/Ability/Ability'

let InvoiceRequestsListPage = (props) => {
  const {
    userProfile: {
      selectors: { data: user },
    },
  } = props

  return (
    <div>
      <Ability
        ability={['showExpense']}
        abilities={user.abilities}
        fallback={() => trans('default.deny')}
      >
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'invoiceRequestsList')}>{trans('global.invoices')}</Link>
        </BreadCrumbs>
        <Section noBorder>
          <Helmet title={trans('request.invoices')} />

          <SectionHeader caption={trans('request.invoices')} />

          <RouteManager>
            {({ getRouteByName }) => (
              <InvoiceRequestsManager>
                {({
                  requests,
                  requestsFilters,
                  changeRequestsFilter,
                  requestsSorter,
                  changeRequestsSorter,
                  areRequestsLoaded,
                  areRequestsLoading,
                  MPKs,
                  currentUser,
                  users,
                  changeRequestsPage,
                  requestsPagination,
                }) => (
                  <div>
                    <InvoiceRequestsListFilters
                      filters={requestsFilters}
                      onChangeFilter={changeRequestsFilter}
                      MPKs={MPKs}
                      currentUser={currentUser}
                      users={users}
                    />
                    <InvoiceRequestsListTable
                      requests={requests}
                      isLoading={!areRequestsLoaded || areRequestsLoading}
                      getRouteByName={getRouteByName}
                      pagination={requestsPagination}
                      changePage={changeRequestsPage}
                      sorter={requestsSorter}
                      onChangeSorter={changeRequestsSorter}
                    />
                  </div>
                )}
              </InvoiceRequestsManager>
            )}
          </RouteManager>
        </Section>
      </Ability>
    </div>
  )
}

const withProfile = userProfile(false, false)

InvoiceRequestsListPage = compose(withProfile)(InvoiceRequestsListPage)

export { InvoiceRequestsListPage }
export default InvoiceRequestsListPage
