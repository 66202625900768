import APIClient from '../../../services/APIClient'
import { createModule } from '../../modules/list/index'

const fetchMethod = ({ filters }, source) => {
  return APIClient.getInvoiceRequests(filters, source)
}

const module = createModule({
  namespace: 'invoice-requests',
  mountPoint: 'invoice-requests',
  paginationByAPI: true,
  fetchMethod,
})

export const { reducer, MOUNT_POINT, actions, selectors } = module
