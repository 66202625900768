import PropTypes from 'prop-types'
import React from 'react'
import { RequestAcceptorsManager } from '../../containers/RequestAcceptorsManager'
import { ExpenseRequestStatusPath } from '../RequestStatusPath'
import { STATUS_SETTLEMENT } from '../../constants/request'
import { ExpenseRequestAccountingDocumentsRight } from './ExpenseRequestAccountingDocumentsRight'
import { PeriodicExpenseRequestStatusPath } from '../RequestStatusPath/PeriodicExpenseRequestStatusPath'

class ExpenseRequestPath extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props
    const isSettlement = () => request['status'] === STATUS_SETTLEMENT

    return (
      <RequestAcceptorsManager request={request} fetchRequest={fetchRequest}>
        {({
          acceptors,
          settlementAcceptors,
          addAcceptor,
          addSettlementAcceptor,
          removeAcceptor,
          removeSettlementAcceptor,
        }) => (
          <div>
            {/*{isSettlement() && (*/}
            {/*    <ExpenseRequestAccountingDocumentsRight*/}
            {/*        request={request}*/}
            {/*    />*/}
            {/*)}*/}

            {/* @TODO split request type for periodic request and check by request.type
                            For future development: periodic expense has been planned (by business) to have same behaviour as normal expense request!
                        */}
            {request.periodic === true ? (
              <PeriodicExpenseRequestStatusPath
                request={request}
                acceptors={acceptors}
                settlementAcceptors={settlementAcceptors}
                addAcceptor={addAcceptor}
                addSettlementAcceptor={addSettlementAcceptor}
                removeAcceptor={removeAcceptor}
                removeSettlementAcceptor={removeSettlementAcceptor}
              />
            ) : (
              <ExpenseRequestStatusPath
                request={request}
                acceptors={acceptors}
                settlementAcceptors={settlementAcceptors}
                addAcceptor={addAcceptor}
                addSettlementAcceptor={addSettlementAcceptor}
                removeAcceptor={removeAcceptor}
                removeSettlementAcceptor={removeSettlementAcceptor}
              />
            )}
          </div>
        )}
      </RequestAcceptorsManager>
    )
  }
}

ExpenseRequestPath.propTypes = {
  request: PropTypes.object.isRequired,
  fetchRequest: PropTypes.func.isRequired,
}

export { ExpenseRequestPath }
export default { ExpenseRequestPath }
