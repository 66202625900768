import React, { useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import trans from '../../../../trans'
import {
  changeExpenseTypeOrder,
  fetchExpenseTypes,
  getExpenseTypesWithRelations,
  isLoading as isExpenseTypesLoading,
  updateExpenseType,
} from '../../../../store/app/expense-type'
import {
  fetchAccountingAccounts,
  getActiveAccountingAccountSelectOptions,
  isLoading as isAccountingAccountsLoading,
} from '../../../../store/app/accounting-account'
import {
  fetchVatNumbers,
  getActiveVatCodeSelectOptions,
  isLoading as isVatNumbersLoading,
} from '../../../../store/app/vat'
import APIClient from '../../../../services/APIClient'
import { AgGridReact } from 'ag-grid-react'
import SelectEditor from '../../../AgGrid/SelectEditor'
import EditButtonRenderer from '../../../AgGrid/EditButtonRenderer'
import ToggleEditor from '../../../AgGrid/ToggleEditor'
import AccountDimensionEditor from '../../../AgGrid/AccountDimensionEditor'
import { flattenObject } from '../../../../utils/flattenObject'
import { get } from 'lodash'
import { AgGridWrapper } from '../../../AgGrid/AgGridWrapper'
import { LoadingOverlay } from '../../../ui/LoadingOverlay'
import { fetchExpenseGroups } from '../../../../store/app/expense-group'

const translateResponse = (item) => {
  return Object.keys(item)
    .map((key) => {
      return {
        [key]: trans(item[key]),
      }
    })
    .reduce((a, b) => ({ ...a, ...b }), {})
}

function ExpenseTypeList(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [accountDimensions, setAccountDimensions] = useState([])
  const { accountingAccounts, vatNumbers, changeExpenseTypeOrder } = props

  const gridData = useMemo(
    () =>
      props.items.map(translateResponse).map((item) => ({
        ...item,
        ...accountDimensions
          .map((dimension) => ({ [dimension.name]: dimension.value }))
          .reduce(flattenObject, {}),
      })),
    [props.items],
  )

  useEffect(() => {
    const { fetchExpenseTypes, fetchExpenseGroups, fetchAccountingAccounts, fetchVatNumbers } =
      props

    fetchAccountingAccounts()
    fetchExpenseGroups()
    fetchExpenseTypes()
    fetchVatNumbers()

    APIClient.getAccountDimensionForDocumentTypes().then(({ data }) => {
      setIsLoaded(true)
      setAccountDimensions(data.map(translateResponse))
    })
  }, [])

  const onSave = useMemo(
    () => (data) => {
      const { updateExpenseType } = props
      const accountDimensionSlugs = accountDimensions.map((dim) => dim.slug)
      const dimensions = accountDimensionSlugs
        .map((slug) => ({ [slug]: data[slug] ? data[slug].value : null }))
        .reduce(flattenObject, {})

      const payload = {
        accounting_account_id: data.accounting_account_id,
        document_element_group_id: data.document_element_group_id,
        exchange_rate_from: data.exchange_rate_from,
        vat_number_id: data.vat_number_id,
        is_visible_in_trip: data.visible_in_trip,
        is_visible_in_expense: data.visible_in_expense,
        is_visible_in_invoice: data.visible_in_invoice,
        is_active: data.is_active,
        slug: data.slug,
        'account-dimensions': dimensions,
      }

      return updateExpenseType(payload).catch((err) => {
        throw err
      })
    },
    [props.updateExpenseType, accountDimensions],
  )

  const renderYesNoBoolean = useMemo(
    () => (grid) => trans(grid.value ? 'accounting.is_active_yes' : 'accounting.is_active_no'),
    [],
  )

  const columnDefs = useMemo(() => {
    const accountDimensionDefs = accountDimensions.map((dim) => {
      return {
        field: dim.slug,
        width: 200,
        headerName: dim.name,
        cellRenderer: AccountDimensionEditor,
        cellRendererParams: {
          accountDimension: dim,
          placeholder: trans('account-dimensions.placeholder'),
        },
        valueGetter: (params) => {
          return params.data.accountDimensionItems.find(
            (item) => item.account_dimension_id === dim.id,
          )
        },
        valueFormatter: (params) => {
          return params.value
            ? `${params.value.accountDimensionItem.code} - ${params.value.accountDimensionItem.name}`
            : '-'
        },
      }
    })

    return [
      {
        field: 'short_name',
        width: 200,
        headerName: trans('accounting.document-element-group'),
        pinned: 'left',
        rowDrag: true,
      },
      {
        field: 'document_element_group_id',
        width: 200,
        headerName: trans('accounting.document-element-group-type'),
        pinned: 'left',
        valueFormatter: (grid) => get(grid.data, 'documentElementGroup.name', '-'),
      },
      {
        field: 'accounting_account_id',
        width: 200,
        headerName: trans('accounting.document-element-account-number'),
        cellRenderer: SelectEditor,
        cellRendererParams: {
          options: accountingAccounts,
          emptyValue: {
            label: trans('global.empty-option'),
            value: null,
          },
        },
        valueFormatter: (grid) => get(grid.data, 'accountingAccount.account_number', '-'),
      },
      ...accountDimensionDefs,
      {
        field: 'vat_number_id',
        width: 200,
        headerName: trans('accounting.document-element-vat-code'),
        cellRenderer: SelectEditor,
        cellRendererParams: {
          options: vatNumbers,
          emptyValue: {
            label: trans('global.empty-option'),
            value: null,
          },
        },
        valueFormatter: (grid) => get(grid.data, 'vatNumber.code', '-'),
      },
      {
        field: 'exchange_rate_from',
        width: 200,
        headerName: trans('accounting.document-element-exchange_rate_from'),
        cellRenderer: SelectEditor,
        cellRendererParams: {
          options: [
            {
              label: trans('accounting.document-element-exchange_rate_from-document'),
              value: 'document',
            },
            {
              label: trans('accounting.document-element-exchange_rate_from-settlement'),
              value: 'settlement',
            },
          ],
          emptyValue: {
            label: trans('global.empty-option'),
            value: null,
          },
        },
        valueFormatter: (grid) =>
          trans(`accounting.document-element-exchange_rate_from-${grid.value}`),
      },
      {
        field: 'visible_in_trip',
        width: 100,
        headerName: trans('accounting.document-element-is_visible_in_trip'),
        cellRenderer: ToggleEditor,
        valueFormatter: renderYesNoBoolean,
      },
      {
        field: 'visible_in_expense',
        width: 100,
        headerName: trans('accounting.document-element-is_visible_in_expense'),
        cellRenderer: ToggleEditor,
        valueFormatter: renderYesNoBoolean,
      },
      {
        field: 'visible_in_invoice',
        width: 100,
        headerName: trans('accounting.document-element-is_visible_in_invoice'),
        cellRenderer: ToggleEditor,
        valueFormatter: renderYesNoBoolean,
      },
      {
        headerName: 'Akcje',
        width: 180,
        cellRenderer: EditButtonRenderer,
        cellRendererParams: {
          onSave,
        },
      },
    ]
  }, [accountDimensions, accountingAccounts, vatNumbers])

  const defaultColDef = useMemo(
    () => ({
      valueFormatter: (grid) => trans(grid.value),
      dragValueFormatter: (grid) => trans(grid.value),
    }),
    [],
  )

  const getRowId = useMemo(() => (params) => params.data.id, [])

  const gridOptions = useMemo(
    () => ({
      suppressMovableColumns: true,
      suppressClickEdit: true,
      suppressColumnVirtualisation: true,
      rowDragManaged: true,
      animateRows: true,
      onRowDragEnd: changeExpenseTypeOrder,
    }),
    [],
  )

  if (!isLoaded || props.isLoaded) {
    return <LoadingOverlay />
  }

  return (
    <AgGridWrapper>
      <AgGridReact
        gridOptions={gridOptions}
        rowData={gridData}
        rowHeight={58}
        headerHeight={40}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
      ></AgGridReact>
    </AgGridWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchExpenseTypes,
      fetchAccountingAccounts,
      fetchExpenseGroups,
      changeExpenseTypeOrder,
      fetchVatNumbers,
      updateExpenseType,
      changeExpenseTypeOrder,
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  // const { accountDimensions } = props;
  //
  return {
    items: getExpenseTypesWithRelations(state),
    isLoading:
      isAccountingAccountsLoading(state) ||
      isVatNumbersLoading(state) ||
      isExpenseTypesLoading(state),
    accountingAccounts: getActiveAccountingAccountSelectOptions(state, []),
    vatNumbers: getActiveVatCodeSelectOptions(state, []),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypeList)
